<script>
export default {
  methods: {
    openPopup () {
      this.$emit('open')
      this.$axios.get(process.env.API_ENDPOINT + 'user/event', {
        params: {
          event_action: 'Открыто окно получения консультации'
        }
      })
    }
  }
}
</script>

<template>
  <div class="banner">
    <div class="banner--content">
      <div class="banner--content--header">
        <div class="banner--content--header--image">
          <img src="@/components/consultation/assets/logo.svg" alt="">
        </div>
        <div class="banner--content--header--text ">
          Поможем списать ваши долги или оплатим кредиты за вас
        </div>
      </div>
      <div class="banner--content--responsibilites">
        <ul>
          <li>Списание долгов и кредитов граждан через банкротство</li>
          <li>Взыскание задолженности по распискам и договорам</li>
          <li>Юридическое обслуживание бизнеса</li>
          <li>Расторжение брака, взыскание алиментов и раздел совместно нажитого имущества</li>
        </ul>
      </div>
      <div class="banner--content--button" @click="openPopup">
        Получить консультацию
      </div>
    </div>
    <div class="banner--img">
      <img src="@/components/consultation/assets/judge.png" alt="Справедливость">
    </div>
  </div>
</template>

<style scoped lang="scss">
.banner {
  width: 100%;
  background-image: url('@/components/consultation/assets/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 302px;
  display: flex;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 10px 15px 0px #223E6526;
  @media (max-width: 769px) {
    height: fit-content;
  }
  &--img {
    margin-top: 18px;
    @media (max-width: 769px) {
      display: none;
    }
  }
  &--content {
    width: 100%;
    padding: 20px 0 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: 769px) {
      font-size: 18px;
      padding: 18px;
    }
    &--responsibilites {
      ul {
        li {
          font-weight: 500;
          font-size: 16px;
          color: #223E65;
          list-style: disc inside;
          padding-left: 1.5em;
          text-indent: -1.5em;
          @media (max-width: 769px) {
            font-size: 14px;
          }

          &::marker {
            color: #223E65;
          }
          &:not(:first-child) {
            margin-top: 16px;
            @media (max-width: 769px) {
              margin-top: 12px;
            }
          }
        }
      }
    }
    &--button {
      background-color: #223E65;
      box-shadow: 7px 7px 17.5px 0px #223E6540;
      border-radius: 5px;
      width: 330px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      cursor: pointer;
    }
    &--header {
      display: flex;
      gap: 20px;
      align-items: center;
      &--text {
        font-weight: 700;
        color: #223E65;
        font-size: 24px;
        letter-spacing: 1px;
        @media (max-width: 769px) {
          font-size: 20px;
        }
      }
    }
  }
}
</style>
