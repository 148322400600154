<template>
  <div class="modal-shadow" @mousedown.self="$emit('close')">
    <transition name="drop" appear>
      <div class="modal-frame">
        <button type="button" class="close" @click="$emit('close')">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
              x="10.8965"
              y="9.5"
              width="13.582"
              height="1.97367"
              transform="rotate(45 10.8965 9.5)"
              fill="#223E65"
            />
            <rect
              x="20.5"
              y="10.896"
              width="13.582"
              height="1.97367"
              transform="rotate(135 20.5 10.896)"
              fill="#223E65"
            />
            <path opacity="0.1" d="M0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15Z" fill="#223E65" />
          </svg>
        </button>
        <header class="modal-header">
          <h5 class="modal-title">
            Получить консультацию
          </h5>
        </header>
        <div class="modal-body row">
          <div class="modal-body--content">
            <div class="modal-body--content--form">
              <div class="modal-body--content--form--input">
                <input
                  v-model.trim="$v.form.firstName.$model"
                  placeholder="Имя"
                  type="text"
                  :class="{'error': $v.form.firstName.$error}"
                  @blur="$v.form.firstName.$touch()"
                >
                <div v-if="$v.form.firstName.$error" class="error-messages">
                  Поле обязательно для заполнения
                </div>
              </div>
            </div>
            <div class="modal-body--content--form--input">
              <input
                v-model.trim="$v.form.lastName.$model"
                placeholder="Фамилия"
                type="text"
                :class="{'error': $v.form.lastName.$error}"
                @blur="$v.form.lastName.$touch()"
              >
              <div v-if="$v.form.lastName.$error" class="error-messages">
                Поле обязательно для заполнения
              </div>
            </div>

            <div class="modal-body--content--form--input">
              <input
                v-model.trim="$v.form.middleName.$model"
                placeholder="Отчество"
                type="text"
                :class="{'error': $v.form.middleName.$error}"
                @blur="$v.form.middleName.$touch()"
              >
              <div v-if="$v.form.middleName.$error" class="error-messages">
                Поле обязательно для заполнения
              </div>
            </div>
            <div class="modal-body--content--form--input">
              <input
                v-model.trim="$v.form.phone.$model"
                placeholder="Телефон"
                type="tel"
                maxlength="12"
                :class="{'error': $v.form.phone.$error}"
                @blur="$v.form.phone.$touch()"
              >
              <div v-if="$v.form.phone.$error" class="error-messages">
                Поле обязательно для заполнения
              </div>
            </div>
          </div>
          <div class="modal-body--content--submit">
            <div class="modal-body--content--submit--container">
              <button class="modal-body--content--submit--container--button" @click.prevent="submitForm">
                Отправить
              </button>
              <div class="modal-body--content--submit--container--text">
                Нажимая кнопку «Отправить», я даю свое согласие на обработку моих персональных данных, в соответствии с Федеральным законом от 27.07.2006 года №152-ФЗ «О персональных данных», на условиях и для целей, определенных в Согласии на обработку персональных данных*
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

const cyrillicValidator = (value) => {
  return !/[^а-яА-ЯёЁ-]/g.test(value)
}

const phoneValidator = (value) => {
  const regex = /^(\+?7|8)[\s-]?\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{2}[\s-]?\d{2}$/
  return regex.test(value)
}
export default {
  data () {
    return {
      form: {
        firstName: '',
        lastName: '',
        middleName: '',
        phone: ''
      }
    }
  },
  validations: {
    form: {
      firstName: {
        required,
        cyrillicValidator
      },
      lastName: {
        required,
        cyrillicValidator
      },
      middleName: {
        required,
        cyrillicValidator
      },
      phone: {
        required,
        phoneValidator
      }
    }
  },
  mounted () {
    document.onkeyup = (e) => {
      if (e.code === 'Escape') {
        this.$emit('close')
      }
    }
  },
  methods: {
    submitForm () {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        this.$axios.$post(process.env.API_ENDPOINT + 'marketing/fssp-lawyer', { ...this.form }).then((response) => {
          this.$emit('close')
        })
      }
    }
  }
}
</script>
<style lang="scss">

.mb-3 {
  margin-bottom: 25px !important;
  @media (max-width: 768px) {
    margin-bottom: 18px !important;
  }
}
</style>
<style lang="scss" scoped>
@import "@pp-frontend/accrual-cards/accrualsTemplate/styles/project-colors";
.error-messages {
  color: rgba(254, 78, 88, 1);
  font-size: 10px;
  line-height: 120%;
  font-weight: 400;
}
.error {
  border: 1px solid rgba(254, 78, 88, 1) !important;
  color: rgba(254, 78, 88, 1) !important;
  &::placeholder {
    color: rgba(254, 78, 88, 1) !important;
  }
}
.drop-enter-active {
  transition: transform 200ms linear;
}

.drop-enter-to {
  transform: translateY(0);
}

.drop-enter {
  transform: translateY(-100px);
}

.modal-shadow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, .5);
  z-index: 15;
}
.modal-body {
  &--content {
    &--submit {
      &--container {
        display: flex;
        flex-direction: column;
        gap: 4px;
        &--button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 56px;
          width: 100%;
          box-shadow: 7px 7px 17.5px 0px rgba(34, 62, 101, 0.25);
          background-color: rgba(34, 62, 101, 1);
          font-size: 14px;
          font-weight: 500;
          border-radius: 5px;
          outline: none;
          border: none;
          color: #fff;
        }
        &--text {
          font-size: 10px;
          font-weight: 400;
          color: rgba(11, 31, 51, .5);
        }
      }
    }
    &--form {
      &--input {
        width: 100%;
        margin-bottom: 20px;
        height: 56px;

        input {
          width: 100%;
          height: 56px;
          font-weight: 400;
          font-size: 16px;
          outline: none;
          border: 1px solid #0B1F331A;
          border-radius: 5px;
          color: rgba(11, 31, 51, .5);
          padding-left: 20px;
          &:placeholder {
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
    }
  }
}

.modal-frame {
  background: white;
  width: 100%;
  padding: 18px 24px;
  max-width: 506px !important;
  border-radius: 8px;
  box-shadow: 4px 4px 6px rgba(14, 63, 102, .2);
  position: relative;
  overflow: hidden;
  @media (max-width: 768px) {
    padding: 18px;
  }

  .modal-header {
    padding: 0;
    margin-bottom: 20px;
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    font-weight: 400;
    color: rgba(17, 58, 89, .6);
    position: absolute;
    right: 24px;
    top: 17px;
    height: 30px;
    width: 30px;
    padding: 0;

    @media (max-width: 768px) {
      top: 16px;
      right: 18px;
    }

    &:focus {
      outline: none;
    }
  }
}

.delete-form {
  .modal-frame {
    max-width: 548px
  }
}

.modal-title {
  font-size: 26px;
  line-height: 100%;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  color: #223E65;
  @media (max-width: 768px) {
    font-size: 22px;
  }
}

.before-leave {
  .modal-title {
    font-size: 22px;
    line-height: 127%;
    background: rgba(237, 30, 36, 0.1);
    border-radius: 16px;
    text-wrap: pretty;
    padding: 16px 24px;
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: flex-start;
    gap: 8px;
    color: #626282;
  }
}

.appeal-popup {
  .modal-frame {
    max-height: 90%;
    overflow-y: scroll;
    overflow-x: visible;
    @media (max-width: 768px) {
      max-height: 100vh;
      height: 100vh;
    }

    &::-webkit-scrollbar {
      height: 4px;
      width: 8px;
      @media (max-width: 768px) {
        display: none;
      }
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #53535354;
      border-radius: 8px;
    }
  }
}
</style>
